.videoPortfolio {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, minmax(250px, 300px));
  padding: 0 165px;
}

@media screen and (max-width: 1280px) {
  .videoPortfolio {
    padding: 0 85px;
  }
}

@media screen and (max-width: 1120px) {
  .videoPortfolio{
    grid-template-rows: repeat(3, minmax(150px, 200px));
  }
}

@media screen and (max-width: 900px) {
  .videoPortfolio{
    grid-template-rows: repeat(4, minmax(150px, 200px));
  }
}

@media screen and (max-width: 600px) {
  .videoPortfolio{
    display: flex;
    flex-direction: column;
    padding: 0 15px;
  }
}

.visible {
  opacity: 1;
  animation: 1s forwards show;
}

.hidden {
  opacity: 0;
}

@keyframes show {
  from {
    transform: translate(0, 40px);
    opacity: 0;
  }

  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes showSubBlock {
  from {
    transform: translate(10px, 20px);
    opacity: 0;
  }

  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes showCard {
  from {
    transform: rotate(5deg);
    opacity: 0;
  }

  to {
    transform: rotate(0);
    opacity: 1;
  }
}

@keyframes hide {
  from {
    transform: translate(0, 0);
    opacity: 1;
  }

  to {
    transform: translate(10px, 20px);
    opacity: 0;
  }
}
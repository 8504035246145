.photoPortfolio__menu-item {
  display: block;
  color: rgb(0, 0, 0);
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0%;
  text-align: center;
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 15px;
  margin: 0 30px;
  transition: 0.2s linear;
}

@media screen and (max-width: 1120px) {
  .photoPortfolio__menu-item {
    font-size: 20px;
    line-height: 20px;
  }
}

@media screen and (max-width: 600px) {
  .photoPortfolio__menu-item{
    margin: 0 5px;
    padding: 10px;
    font-size: 14px;
    line-height: 16px;
  }
}
.cooperation__item-overlay{
  background-color: 0 0 15px rgba(202, 255, 225, 1.00);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  height: 100%;
  width: 100%;
  opacity: .2;
}

.cooperation__item-overlay_green{
  background-color: rgba(202, 255, 225, 1.00);
}

.cooperation__item-overlay_pink{
  background-color: rgba(255, 231, 240, 1.00);
}

.cooperation__item-overlay_blue{
  background-color: rgba(221, 245, 255, 1.00);
}

.cooperation__item-overlay_yellow{
  background-color: rgba(255, 238, 201, 1.00);
}

.cooperation__item-overlay_light-green{
  background-color: rgba(219, 255, 196, 1.00);
}

.cooperation__item-overlay_orange{
  background-color: rgba(255, 214, 196, 1.00);
}
.services__cells{
  margin-top: 80px;
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  justify-content: space-around;
  box-sizing: border-box;
}

@media screen and (max-width: 1120px) {
  .services__cells{
    margin-top: 36px;
  }
}

@media screen and (max-width: 600px) {
  .services__cells{
    margin-top: 0px;
    margin-bottom: 30px;
  }
}
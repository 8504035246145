.services__cell-description {
  margin: 20px 0 0;
  color: rgb(71, 68, 78);
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0%;
  text-align: left;
  text-align: justify;
}

@media screen and (max-width: 1120px) {
  .services__cell-description{
    max-width: 400px;
  }
}

@media screen and (max-width: 1028px) {
  .services__cell-description{
    font-size: 14px;
    line-height: 28px;
    max-width: 310px;
  }
}

@media screen and (max-width: 600px) {
  .services__cell-description{
    width: 100%;
    max-width: 100%;
    margin-top: 5px;
    line-height: 26px;
  }
}
.intro__subtitle {
  color: rgb(37, 32, 47);
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0%;
  text-align: left;
}

@media screen and (max-width: 1028px) {
  .intro__subtitle{
    font-size: 19px;
    margin: 10px 0 0;
  }
}
.popup__exit-button{
  background: none;
  border: none;
  padding: 0;
  width: 50px;
  position: absolute;
  top: 50px;
  right: 50px;
  cursor: pointer;
  transition: opacity .2s linear;
}

.popup__exit-button:hover{
  opacity: .7;
}

@media screen and (max-width: 1280px) {
  .popup__exit-button{
    width: 36px;
    height: 36px;
  }
}
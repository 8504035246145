.intro__title {
  color: rgba(0, 0, 0, 0.6);
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0%;
  text-align: left;
}


@media screen and (max-width: 1028px) {
  .intro__title{
    margin: 0;
    font-size: 20px;
  }
}
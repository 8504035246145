.photoPortfolio__item-photo{
  width: 100%;
  display: block;
  max-height: 170px;
}

@media screen and (max-width: 600px) {
  .photoPortfolio__item-photo{
    border-radius: 15px;
    max-height: 195px;
  }
}
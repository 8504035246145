.footer__menu-title{
  margin: 25px 0 0 auto;
  text-align: left;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0;
  color: #000;
  max-width: 150px;
}

@media screen and (max-width: 600px) {
  .footer__menu-title{
    margin: 0 auto 0 0;
  }
}

.services__cell-title {
  color: rgb(37, 32, 47);
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0%;
  text-align: left;
  margin-left: 20px;
}

@media screen and (max-width: 600px) {
 .services__cell-title{
  font-size: 20px;
  line-height: 24px;
  margin: auto auto auto 15px;
 } 
}
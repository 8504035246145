.photoPortfolio__list-items{
  margin: 60px auto 0;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-flow: row;
  column-gap: 30px;
  row-gap: 50px;
  padding: 0;
  padding-bottom: 100px;
}

@media screen and (max-width: 1120px) {
  .photoPortfolio__list-items{
    padding-bottom: 0px;
    row-gap: 30px;
  }
}

@media screen and (max-width: 600px) {
  .photoPortfolio__list-items{
    row-gap: 20px;
    margin: 30px auto 0;
    max-height: 500px;
    position: relative;
    display: block;
    width: 290px;
    box-sizing: border-box;
  }
}
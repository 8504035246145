.header__menu-list-link {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: #000;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0%;
  text-align: left;
}

@media screen and (max-width: 1028px) {
  .header__menu-list-link {
    font-size: 16px;
    line-height: 22px;
  }
}

@media screen and (max-width: 940px) {
  .header__menu-list-link {
    font-size: 20px;
    line-height: 22px;
  }
}

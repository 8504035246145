.about-me__menu-title {
  color: rgb(37, 32, 47);
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0%;
  text-align: center;
  text-decoration: underline;
}

@media screen and (max-width: 1120px) {
  .about-me__menu-title {
    font-size: 22px;
    line-height: 28px;
  }
}

@media screen and (max-width: 600px) {
  .about-me__menu-title{
    margin: 0 auto 20px;
    font-size: 18px;
    line-height: 24px;
  } 
}
.intro{
  margin: 120px auto 0 0;
  display: flex;
  flex-direction: column;
  padding-left: 165px;
}

@media screen and (max-width: 1280px) {
  .intro{
    padding: 0 0 0 85px;
  }
}


@media screen and (max-width: 1028px) {
  .intro{
    padding: 0 0 0 50px;
  }
}

@media screen and (max-width: 600px) {
  .intro{
    margin: 350px auto 0 0;
    padding:  0 15px;
  }
}
.about-me__description {
  margin: 20px 0 0;
  color: rgb(71, 68, 78);
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0;
  text-align: left;
  min-width: 450px;
  margin-right: 50px;
}

@media screen and (max-width: 1120px) {
  .about-me__description {
    font-size: 14px;
    line-height: 28px;
  }
}

@media screen and (max-width: 1028px) {
    .about-me__description{
        text-align: justify;
        margin: 20px auto 0;
    }
}

@media screen and (max-width: 580px) {
  .about-me__description{
    min-width: 280px;
    line-height: 26px;
  }
}
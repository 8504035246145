.services__cell{
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 350px;
  margin-right: 20px;
  opacity: 0;
}

@media screen and (max-width: 1120px) {
  .services__cell{
    margin: 0;
    max-width: 450px;
  }
  .services__cell:last-child{
    margin: 20px 0 0;
  }
}

@media screen and (max-width: 600px) {
  .services__cell{
    width: 100%;
    margin: 30px auto 0;
    min-width: 290px;
  }
}
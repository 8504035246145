.about-me{
  margin: 60px auto 0;
  padding: 0 165px;
  background: linear-gradient(
  rgba(134, 67, 220, .15),
  #fff );
  border-radius: 100px;
}

@media screen and (max-width: 1280px) {
  .about-me{
    margin: 60px 20px 0;
    padding: 0 65px;
  }
}

@media screen and (max-width: 520px) {
  .about-me{
    padding: 0 15px;
    margin: 30px 0 0;
    border-radius: 0;
  }
}
.footer__author {
  margin: auto 0 0 0;
  line-height: 25px;
  font-size: 18px;
  padding: 18px 0 18px;
}

@media screen and (max-width: 600px) {
  .footer__author{
    margin: 40px auto 0 0;
    padding: 0;
    max-height: 25px;
  }
}
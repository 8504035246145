.photoPortfolio__menu{
  display: flex;
  margin: 0 auto;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .photoPortfolio__menu{
    margin: 15px auto;

  }
}
.header__menu-list-item {
  list-style: none;
  margin-right: 20px;
  opacity: .6;
  transition: .3s;
}

.header__menu-list-item:hover{
  opacity: 1;
}

@media screen and (max-width: 940px) {
  .header__menu-list-item{
    margin: 20px auto;
  }
}
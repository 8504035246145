.popup__container{
  margin: 0;
  width: 60%;
  position: relative;
  margin: auto;
}
.popup__container .swiper-3d .swiper-slide-shadow{
  background: rgba(0, 0, 0, 0);
}

@media screen and (max-width: 1280px) {
  .popup__container {
    width: 70%;
  }
}

@media screen and (max-width: 1120px) {
  .popup__container {
    width: 80%;
  }
}
.footer__item-icon{
  display: inline-block;
  height: 25px;
  width: 25px;
  margin: auto 0;
}

@media screen and (max-width: 600px) {
  .footer__item-icon{
   height: 40px;
   width: 40px;
  }
}
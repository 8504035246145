.testimonials {
  padding: 0 165px;
  margin-bottom: 100px;
}

.testimonials .swiper-button-next {
  color: #8643dc;
  height: 60px;
  width: 60px;
  transition: 0.2s linear;
}

.testimonials .swiper-button-next:hover {
  transform: scale(1.1);
}

.testimonials .swiper-button-prev {
  color: #8643dc;
  height: 60px;
  width: 60px;
  transition: 0.2s linear;
}

.testimonials .swiper-button-prev:hover {
  transform: scale(1.1);
}

.testimonials .swiper-pagination-bullet-active {
  background: #8643dc;
}

@media screen and (max-width: 1280px) {
  .testimonials {
    padding: 0 85px;
  }
}

@media screen and (max-width: 600px) {
  .testimonials {
    padding: 0 15px;
  }

  .testimonials .swiper-button-next {
    height: 15px;
    width: 15px;
  }
  .testimonials .swiper-button-prev {
    height: 15px;
    width: 15px;
  }
}

.testimonials__cell-icon{
  width: 50px;
  height: 50px;
  margin: 20px auto 0px;
}

@media screen and (max-width: 600px) {
  .testimonials__cell-icon{
    width: 40px;
    height: 40px;
  }
}
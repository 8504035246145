.section-subtitle {
  color: rgb(37, 32, 47);
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0%;
  text-align: left;
  max-width: 600px;
}

@media screen and (max-width: 1120px) {
  .section-subtitle {
    font-size: 28px;
    line-height: 36px;
    margin: 24px 0;
    max-width: 300px;
  }
}

@media screen and (max-width: 600px) {
  .section-subtitle{
    margin: 20px 0 0;
    font-size: 20px;
    line-height: 24px;
  }
}
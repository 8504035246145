.header__telephone-cell{
  display: flex;
  text-decoration: none;
  margin: auto 0 auto auto;
  background: rgb(134, 67, 220);
  color: #fff;
  padding: 5px;
  padding-right: 10px;
  border-radius: 50px;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0%;
  text-align: right;
  align-items: center;
  letter-spacing: 1.5px;
}

@media screen and (max-width: 1028px) {
  .header__telephone-cell {
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    align-items: center;
    border-radius: 50px;
  }
}

@media screen and (max-width: 940px) {
  .header__telephone-cell {
    margin: auto auto 150px auto;
    padding: 10px;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 2px;
  }
}
.about-me__cells {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 50px auto 0;
}

@media screen and (max-width: 1028px) {
  .about-me__cells {
    flex-direction: column-reverse;
  }
}

.footer__item-link{
  height: 30px;
  width: 150px;
  display: flex;
  transition: .2s linear;
  text-decoration: none;

}

.footer__item-link:hover .footer__link-text{
  color: rgb(134, 67, 220);
}


@media screen and (max-width: 600px) {
  .footer__item-link{
   height: 40px;
  }
}
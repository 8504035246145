html {
  scroll-behavior: smooth;
}

.page {
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  font-family: "Red Hat Display", Arial, sans-serif;
  max-width: 1440px;
  background-image: url(../../images/introPhoto.png);
  background-repeat: no-repeat;
  background-size: 765px 850px;
  background-position: top -100px right 0;
}

@media screen and (max-width: 1280px) {
  .page {
    background-size: 612px 680px;
    background-position: top 0px right 50px;
  }
}

@media screen and (max-width: 1120px) {
  .page {
    background-size: 535px 595px;
    background-position: top 0px right 0px;
  }
}

@media screen and (max-width: 1000px) {
  .page {
    background-size: 459px 510px;
    background-position: top 0px right -40px;
  }
}

@media screen and (max-width: 600px) {
  .page {
    background-size: 321px 357px;
    background-position: top 20px center;
  }
}

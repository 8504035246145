.about-me__subtitle {
  margin: 24px 0 0;
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

@media screen and (max-width: 1120px) {
  .about-me__subtitle {
    font-size: 16px;
    line-height: 18px;
  }
}

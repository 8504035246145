.intro__button {
  color: rgb(255, 255, 255);
  border-radius: 100px;
  background: rgb(134, 67, 220);
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0%;
  text-align: center;
  text-decoration: none;
  padding: 15px 46px;
  display: flex;
  margin: 50px auto 0 0;
  transition: .5s opacity;
}

.intro__button:hover{
  opacity: .8;
}

@media screen and (max-width: 1028px) {
  .intro__button{
    font-size: 18px;
    padding: 10px 15px;
  }
}

.services{
  margin: 20px auto;
  max-width: 1280px;
  padding: 0 165px;
}

@media screen and (max-width: 1280px) {
  .services{
    padding: 0 85px;
  }
}

@media screen and (max-width: 1028px) {
  .services{
    padding: 0 50px;
  }
}

@media screen and (max-width: 600px) {
  .services{
    margin-top: 40px;
    padding: 0 15px;
  }
}
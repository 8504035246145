.videoPortfolio__link {
  display: block;
  text-align: right;
  color: rgb(37, 32, 47);
  color: #fff;
  background: rgb(134, 67, 220);
  padding:  5px 15px;
  border-radius: 30px;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0;
  text-decoration: none;
  margin: 175px 0 auto auto;
  height: 28px;
  transition: opacity 0.2s linear;
}

.videoPortfolio__link:hover {
  opacity: 0.8;
}

@media screen and (max-width: 1120px) {
  .videoPortfolio__link {
    margin-top: 105px;
    font-size: 20px;
    line-height: 28px;
  }
}

@media screen and (max-width: 600px) {
  .videoPortfolio__link{
    height: 20px;
    font-size: 16px;
    margin: 20px auto 30px 0;
    text-align: left;
    line-height: 18px;
  }
}
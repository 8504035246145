.disappearance{
  animation: .3s forwards disappearance;
  opacity: 1;
}

@keyframes disappearance {
  from {
    transform: translate(0px, 0px);
    opacity: 1;
  }

  to {
    transform: translate(0px, 80px);
    opacity: 0;
  }
}

@media screen and (max-width: 600px) {
  @keyframes disappearance {
    from {
      transform: translate(0px, 0px);
      opacity: 1;
    }
  
    to {
      transform: translate(0px, 40px);
      opacity: 0;
    }
  }
}
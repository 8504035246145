.testimonials__review-cell{
  margin: 0 auto;
  width: 50%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  min-height: 650px;
}

@media screen and (max-width: 1120px) {
  .testimonials__review-cell{
    width: 60%;
  }
}

@media screen and (max-width: 600px) {
  .testimonials__review-cell{
    min-height: 700px;
    width: 100%;
  }
}
.about-me__menu{
  margin: 50px auto 0;
  display: flex;
  padding: 0;
  flex-direction: column;
}

@media screen and (max-width: 600px) {
 .about-me__menu{
  margin: 25px auto 0;
 } 
}
.about-me__photo-cell {
  max-width: 350px;
  max-height: 350px;
  border-radius: 30px;
  box-shadow: 0 0 15px #8643dc;
  margin: auto 0 auto auto;
  opacity: 0;
}

@media screen and (max-width: 1028px) {
  .about-me__photo-cell{
    margin: 0 auto 40px;
  }
}

@media screen and (max-width: 520px) {
  .about-me__photo-cell{
    max-width: 100%;
  }
}
.cooperation {
  padding: 0 165px;
  margin: 0 auto;
}

@media screen and (max-width: 1280px) {
  .cooperation {
    padding: 0 85px;
  }
}

@media screen and (max-width: 600px) {
  .cooperation {
    padding: 0 15px;
  }
}

.cooperation .swiper-pagination-bullet-active {
  background: #8643dc;
  position: relative;
}


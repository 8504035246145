.header__logo{
  height: 40px;
  width: 40px;
  margin: auto 0;
}

@media screen and (max-width: 600px) {
  .header__logo{
    margin: auto 0;
  }
}
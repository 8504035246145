.section-title {
  color: rgb(134, 67, 220);
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  border-bottom: 2px solid rgb(134, 67, 220);
  display: inline-block;
  padding-bottom: 5px;
  margin: 0;
  padding-top: 100px;
}

@media screen and (max-width: 1120px) {
  .section-title{
    padding-top: 70px;
    font-size: 20px;
  }
}

@media screen and (max-width: 1028px) {
  .section-title{
    padding-top: 50px;
  }
}

@media screen and (max-width: 600px) {
  .section-title{
    padding-top: 30px;
    font-size: 18px;
    line-height: 22px;
  }
}
.intro__title_accent {
  font-size: 60px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0%;
  text-align: left;
}


@media screen and (max-width: 1028px) {
  .intro__title_accent{
    font-size: 40px;
    line-height: 70px;
  }
}

@media screen and (max-width: 600px) {
  .intro__title_accent{
    font-size: 30px;
    line-height: 40px;
  }
}
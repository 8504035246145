.header{
  display: flex;
  margin: 65px auto 0;
  padding: 0 120px 0 165px;
  height: 40px;
  width: 100%;
  box-sizing: border-box;
}

@media screen and (max-width: 1280px) {
  .header{
    padding: 0 85px;
  }
}

@media screen and (max-width: 1028px) {
  .header{
    margin: 35px auto 0 0;
    padding: 0 50px;
  }
}

@media screen and (max-width: 600px) {
  .header{
    box-sizing: border-box;
    width: 94%;
    justify-content: space-between;
    margin: 15px auto 0;
    padding: 0 0px 0 15px;

  }
}
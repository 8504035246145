.testimonials__cell-text {
  color: rgb(99, 96, 102);
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0%;
  text-align: center;
  margin: auto auto;
  background: radial-gradient(rgba(255, 137, 204, 0.2) 40%, #fff 75%);
}

@media screen and (max-width: 1028px) {
  .testimonials__cell-text {
    font-size: 14px;
    line-height: 28px;
  }
}

@media screen and (max-width: 600px) {
  .testimonials__cell-text{
    padding: 0 10px;
    font-size: 14px;
    line-height: 26px;
  }
}
.videoPortfolio__container {
  grid-column: 1 / 3;
  grid-row: 2 / 3;
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 1120px) {
  .videoPortfolio__container{
    margin-top: 50px;
  }
}

@media screen and (max-width: 1028px) {
  .videoPortfolio__container {
    margin-top: 20px;
    min-height: 500px;
    flex-direction: column;
    grid-row: 2 / 5;
  }
}

@media screen and (max-width: 600px) {
  .videoPortfolio__container {
    margin-top: 0px;
    min-height: 400px;
  }
}

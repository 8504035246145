.header__telephone-icon{
  height: 30px;
  width: 30px;
  margin: auto;
  display: inline-block;
  margin-right: 5px;
}

@media screen and (max-width: 770px) {
  .header__telephone-icon{
    height: 40px;
    width: 40px;
    margin: auto;
    display: inline-block;
    margin-right: 5px;
  } 
}
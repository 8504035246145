.footer__title {
  color: rgb(33, 35, 45);
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0%;
  text-align: left;
  color: #000;
  margin: 15px 0 0;
}

@media screen and (max-width: 600px) {
  .footer__title{
    font-size: 36px;
    line-height: 36px;
    text-align: center;
  }
}